import { disableScroll, enableScroll } from "./_helpers.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class Navbar {
  constructor() {
    this.toggleBtn = document.querySelector('#toggle-menu')
    this.menuContainer = document.querySelector('#menu')
    this.header = document.querySelector('header')

    this.show = false

    this.toggleBtn.addEventListener('click', () => this.toggleMenu())
    this.toggleBtn.addEventListener('keyup', () => this.toggleMenu())
  }

  toggleMenu = () => {
    this.menuContainer.style.height = window.innerHeight - this.header.offsetHeight + 'px'

    this.show = !this.show
    this.menuContainer.classList.toggle('is-showed')

    if(this.show) {
      disableScroll()
    } else {
      enableScroll()
    }
  }
}

export default Navbar;
