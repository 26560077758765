class Map {
  constructor(mapEl, mapData) {
    this.center = mapData.center
    this.settings = mapData.settings
    this.zoom = this.settings.zoom
    this.marker = mapData.marker
    this.markerTitle = mapData.markerTitle
    this.markerUrl = mapData.markerUrl
    this.styles = mapData.styles

    this.mapContainer = mapEl

    this.initialized = false

    if(!this.initialized) this.init()
  }

  loadMap = (map) => {
    if (map.classList.contains("loaded")) {
      return true;
    }

    const googleMap = new google.maps.Map(map, {
      zoom: window.innerWidth < 765 ? 11 : this.zoom,
      gestureHandling: window.innerWidth < 765 ? "cooperative" : "cooperative",
      center: {
        lat: window.innerWidth < 765 ? this.center.lat : this.settings.lat,
        lng: window.innerWidth < 765 ? this.center.lng : this.settings.lng,
      },
      zoomControl: this.zoom,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
      mapTypeControl: this.settings.mapTypeControl,
      streetViewControl: this.settings.streetViewControl,
      rotateControl: this.settings.rotateControl,
      rotateControlOptions: this.settings.rotateControlOptions,
      scaleControl: this.settings.scaleControl,
      fullscreenControl: this.settings.fullscreenControl,
      styles: this.styles,
    });

    let googleMarker = new google.maps.Marker({
      position: {
        lat: this.center.lat,
        lng: this.center.lng,
      },
      map: googleMap,
      icon: this.marker,
      url: this.markerUrl,
      title: this.markerTitle,
    });
    google.maps.event.addListener(googleMarker, "click", function () {
      // window.location.href = this.url;
      window.open(this.url, "_blank");
    });
    map.classList.add("loaded");
  };

  init = () => {
    const onIntersection = (entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          this.loadMap(this.mapContainer);
        }
      }
    }

    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0.5,
    });

    observer.observe(this.mapContainer);
  };
}

export default Map;
