import lazyLoadImages from "./components/lazyLoad";
import nav from "./components/navbar.js";
import map from "./components/map.js"
import Swiper, {Navigation, Grid} from 'swiper'
import fsLightbox from "fslightbox";
import AOS from 'aos'
import 'aos/dist/aos.css'

import 'swiper/css/grid'

import cookie from "./components/cookie-popup.js"
lazyLoadImages();
document.body.addEventListener("force_lazyload", (e) => {
  lazyLoadImages();
});

document.addEventListener("DOMContentLoaded", () => {
  const Nav = new nav();
  const mapElement = document.querySelector("#map");
  const Cookie = new cookie();

  const lightbox = new FsLightbox()
  AOS.init({
    once: true,
  })

  if (typeof mapElement != "undefined" && mapElement != null) {
    const Map = new map(mapElement, map_data);
  }

  const swipers = document.querySelectorAll('.swiper-realizations')

  swipers.forEach((s, i) => {
    const navNext = s.querySelector('.swiper-button-next')
    const navPrev = s.querySelector('.swiper-button-prev')

    const lightboxes = s.querySelectorAll('a[data-fslightbox]')
    lightboxes.forEach(lightbox => {
      lightbox.setAttribute('data-fslightbox', `realizations-${ i + 1 }`)
    })

    const swiperClass = `swiper-realizations-${ i + 1 }`
    const navNextClass = `swiper-button-next-${ i + 1 }`
    const navPrevClass = `swiper-button-prev-${ i + 1 }`

    s.classList.add(swiperClass)
    navNext.classList.add(navNextClass)
    navPrev.classList.add(navPrevClass)

    const swiper = new Swiper(`.${swiperClass}`, {
      modules: [Navigation],
      slidesPerView: 1,
      navigation: {
        nextEl: `.${navNextClass}`,
        prevEl: `.${navPrevClass}`,
      },
      allowTouchMove: true,
    })
  })

  refreshFsLightbox()

  const swiperGallery = new Swiper('.swiper-gallery', {
    modules: [Navigation, Grid],
    spaceBetween: 10,
    slidesPerView: 1,
    grid: {
      rows: 1,
      fill: 'row',
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 2,
        grid: {
          rows: 2,
        },
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 4,
        grid: {
          rows: 3,
        },
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    allowTouchMove: true,
  })

  CalculatePageMargin()
})

document.addEventListener('resize', () => CalculatePageMargin())

const CalculatePageMargin = () => {
  const header = document.querySelector('header')
  const main = document.querySelector('main')

  main.style.marginTop = header.offsetHeight + 'px'
}
